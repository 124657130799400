import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardTitle,
    CardBody,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Label,
    FormGroup
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { AvForm, AvField,AvRadioGroup,AvRadio } from "availity-reactstrap-validation"
import { httpClient } from 'helpers/api_helper';

const View = props => {
    const [formData,setformData] = useState({})



    const getformData = async () => {
        try {
            const res =  await httpClient.get(`/api/user/${props.match.params.id}`)
            if(res?.data?.result && res?.data?.result?.length != 0){
                setformData(res.data.result[0])
            }
        } catch (error) {
            console.log("error",error)
        }
    }

    useEffect(()=>{
        getformData()
    },[])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Customer Details</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={12}>
                                <h6 className="page-title">
                                    Customer Detail
                                </h6>
                                <Link style={{ float: "right" }} to="/customers" >Customer List</Link>
                            </Col>
                        </Row>

                        <Card>
                            <CardTitle tag="h5">
                                Personal Details
                            </CardTitle>
                            <CardBody>
                                <Row>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td className='bold'>{formData?.name}</td>
                                            </tr>
                                            <tr>
                                                <td>Phone</td>
                                                <td className='bold'>{formData?.phone}</td>
                                            </tr>
                                            <tr>
                                                <td>Aadhar No</td>
                                                <td className='bold'>{formData?.aadharNo}</td>
                                            </tr>
                                             <tr>
                                                <td>DOB</td>
                                                <td className='bold'>{formData?.dob}</td>
                                            </tr>
                                            <tr>
                                                <td>Address</td>
                                                <td>
                                                    <table class="table"> 
                                                        <tbody>
                                                            <tr>
                                                                <td>House No</td>
                                                                <td>{formData?.address?.houseNo}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Street</td>
                                                                <td>{formData?.address?.streetOne}, {formData?.address?.streetOne}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Landmark</td>
                                                                <td>{formData?.address?.landmark}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>City</td>
                                                                <td>{formData?.address?.city}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>State</td>
                                                                <td>{formData?.address?.state}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Pincode</td>
                                                                <td>{formData?.address?.pincode}</td>
                                                            </tr>
                                                            
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Spouse Details</td>
                                                <td>
                                                    <table class="table"> 
                                                        <tbody>
                                                            <tr>
                                                                <td>Name</td>
                                                                <td>{formData?.spouseDetails?.firstName}, {formData?.spouseDetails?.firstName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Phone</td>
                                                                <td>{formData?.address?.phone}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Aadhar No.</td>
                                                                <td>{formData?.address?.aadharNo}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>DOB</td>
                                                                <td>{formData?.address?.dob}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Relation</td>
                                                                <td>{formData?.address?.realtion}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Row>
                            </CardBody>
                        </Card>
                       
                    </div>
                </Container>
            </div>

        </React.Fragment>
    )
}

View.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(View)
