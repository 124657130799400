import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Label,
    FormGroup
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { httpClient } from 'helpers/api_helper';

const Add = props => {

    const [cities,setCities] = useState([])

    const handleSubmit = async (event,values)=>{
        try {
            console.log("values",values)
            await httpClient.post('api/auth/register',values)
            alert("User added successfully")
            props.history.push('/customers')
        } catch (error) {
            console.log("error",error)
        }
    }

    const getcities = async ()=>{
        try {
            const res = await httpClient.get('/api/branches?fields=city')
            if(res?.data?.result && res?.data?.result?.length > 0){
                setCities(res?.data?.result)
            }
        } catch (error) {
            console.log("error",error)
        }
    }

    useEffect(()=>{
        getcities()
    },[])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Add User</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={8}>
                                <h6 className="page-title">Create User</h6>
                                <Link style={{ float: "right" }} to="/customers" >Users List</Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <AvForm 
                                            className="needs-validation"
                                            onValidSubmit={handleSubmit}
                                        >
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="name">
                                                            Name
                                                        </Label>
                                                        <AvField
                                                            name="name"
                                                            placeholder="Enter name"
                                                            type="text"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="name"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                
                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="phone">
                                                            Phone
                                                        </Label>
                                                        <AvField
                                                            name="phone"
                                                            placeholder="Enter phone"
                                                            type="text"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="phone"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="email">
                                                            Email
                                                        </Label>
                                                        <AvField
                                                            name="email"
                                                            placeholder="Enter email"
                                                            type="email"
                                                            className="form-control"
                                                            id="email"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="password">
                                                                Password
                                                            </Label>
                                                            <AvField
                                                                name="password"
                                                                placeholder="Enter password"
                                                                type="password"
                                                                className="form-control"
                                                                validate={{ required: { value: true } }}
                                                                id="password"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                            </Row>
                                            <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="role">
                                                                Role
                                                            </Label>
                                                            <AvField
                                                                name="role"
                                                                type="select"
                                                                className="form-control"
                                                                validate={{ required: { value: true } }}
                                                                id="role"
                                                            >
                                                                <option value="">select Role</option>
                                                                <option value="seeker">Seeker</option>
                                                                <option value="donor">Donor</option>
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="city">
                                                                City
                                                            </Label>
                                                            <AvField
                                                                name="address.city"
                                                                placeholder="Enter city"
                                                                type="select"
                                                                className="form-control"
                                                                validate={{ required: { value: true } }}
                                                                id="city"
                                                            >   
                                                                <option value="">select city</option>
                                                                {
                                                                    cities && cities.length != 0 &&
                                                                    cities.map((el)=>{
                                                                        return <option value={el.city}>{el.city}</option>
                                                                    })
                                                                }
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>

                                            </Row>
                                        
                                            <div className='text-center'>
                                                <Button color="primary" type="submit">
                                                    Submit form
                                                </Button>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

        </React.Fragment>
    )
}

Add.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(Add)
