import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { httpClient } from "helpers/api_helper"

const Add = props => {
  const [users, setUsers] = useState([])
  const [branches, setBranches] = useState([])
  const [cities, setCities] = useState([])

  const handleSubmit = async (event, values) => {
    console.log("values", values)
    try {
      // await httpClient.post(`/api/staff/${values.userId}`,{
      //     "role":"staff",
      //     "idOrg":values.idOrg
      // })
      // alert("Staff created successfully")
      try {
        console.log("values", values)
        values.roles = [
          {
            idOrg: values.idOrg,
            role: "staff",
          },
        ]
        await httpClient.post("api/auth/register", { ...values, role: "staff" })
        alert("Staff added successfully")
        props.history.push("/staffs")
      } catch (error) {
        console.log("error", error)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const getBranches = async () => {
    try {
      const res = await httpClient.get("/api/branches?fields=name")

      if (res?.data?.result && res?.data?.result?.length > 0) {
        setBranches(res.data.result)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  const getUsers = async () => {
    try {
      const res = await httpClient.get("/api/user?fields=name&role=staff")
      setUsers(res.data.result)
    } catch (error) {
      console.log(error)
    }
  }

  const getcities = async () => {
    try {
      const res = await httpClient.get("/api/branches?fields=city")
      if (res?.data?.result && res?.data?.result?.length > 0) {
        setCities(res?.data?.result)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    getBranches()
    getUsers()
    getcities()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Offers</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Create Staff</h6>
                <Link style={{ float: "right" }} to="/staffs">
                  Staff List
                </Link>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={handleSubmit}
                    >
                      <Row>
                        {/* <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom01">
                                                            Select User
                                                        </Label>
                                                        <AvField
                                                            name="userId"
                                                            type="select"
                                                            validate={{ required: { value: true } }}
                                                            className="form-control"
                                                            id="validationCustom01"
                                                        >
                                                            <option value=''>Select User</option>
                                                            {
                                                                users && users?.length > 0 &&
                                                                users.map((el)=>{
                                                                    return <option value={el._id}>{el.name}</option>
                                                                })
                                                            }
                                                        </AvField>
                                                    </FormGroup>
                                                </Col> */}
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Select Branch
                            </Label>
                            <AvField
                              name="idOrg"
                              type="select"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                            >
                              <option value="">Select Branch</option>
                              {branches &&
                                branches?.length > 0 &&
                                branches.map(el => {
                                  return (
                                    <option value={el._id}>{el.name}</option>
                                  )
                                })}
                            </AvField>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="name">Name</Label>
                            <AvField
                              name="name"
                              placeholder="Enter name"
                              type="text"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="name"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="phone">Phone</Label>
                            <AvField
                              name="phone"
                              placeholder="Enter phone"
                              type="text"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="phone"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="email">Email</Label>
                            <AvField
                              name="email"
                              placeholder="Enter email"
                              type="email"
                              className="form-control"
                              id="email"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="password">Password</Label>
                            <AvField
                              name="password"
                              placeholder="Enter password"
                              type="password"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="password"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="role">Role</Label>
                            <AvField
                              name="role"
                              type="select"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="role"
                            >
                              <option value="">select Role</option>
                              <option value="manager">Manager</option>
                              <option value="staff">Staff</option>
                            </AvField>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="city">City</Label>
                            <AvField
                              name="address.city"
                              placeholder="Enter city"
                              type="select"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="city"
                            >
                              <option value="">select city</option>
                              {cities &&
                                cities.length != 0 &&
                                cities.map(el => {
                                  return (
                                    <option value={el.city}>{el.city}</option>
                                  )
                                })}
                            </AvField>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="text-center">
                        <Button color="primary" type="submit">
                          Submit form
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

Add.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Add)
