import PropTypes from 'prop-types'
import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Label,
    FormGroup
} from "reactstrap"
import { Link } from "react-router-dom"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//i18n
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { httpClient } from 'helpers/api_helper';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Add = props => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSubmit = async (event,values)=>{
        try {
            console.log("values",values)
            await httpClient.post('/api/auth/change_passowrd',values)
            props.history.push('/logout')
        } catch (error) {
            console.log("error",error)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Settings</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">

                        <Row>
                            <Col lg={12}>
                                <Box
                                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                                >
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                        <Tab label="Change Password" {...a11yProps(0)} />
                                    </Tabs>
                                    <TabPanel value={value} index={0}>
                                        <AvForm
                                            className="needs-validation"
                                            onValidSubmit={handleSubmit}
                                        >
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="email">
                                                            Email
                                                        </Label>
                                                        <AvField
                                                            name="email"
                                                            placeholder="Enter email address"
                                                            type="email"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="email"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="password">
                                                        Password
                                                    </Label>
                                                    <AvField
                                                        name="password"
                                                        placeholder="Enter password"
                                                        type="password"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="password"
                                                    />
                                                </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="newPassword">
                                                            New Password
                                                        </Label>
                                                        <AvField
                                                            name="newPassword"
                                                            placeholder="Enter new password"
                                                            type="password"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="newPassword"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                
                                            </Row>

                                            <div className='text-center'>
                                                <Button color="primary" type="submit">
                                                    Update Password
                                                </Button>
                                            </div>
                                        </AvForm>
                                    </TabPanel>
                                </Box>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

        </React.Fragment>
    )
}

Add.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(Add)
