import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  FormGroup,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"

import { Button as MUIButton } from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"

//i18n
import { withTranslation } from "react-i18next"
import { DataGrid } from "@mui/x-data-grid"
import { httpClient } from "helpers/api_helper"
import { getIndexTitleDescription, TABLE_LIMIT } from "helpers/common"

const List = props => {
  const [notifications, setNotifications] = useState([])
  const [notificationsCount, setNotificationsCount] = useState(0)
  const [page, setPage] = useState(1)

  const mapLinks = (page, docId) => {
    const obj = {
      user: `/customers/${docId}`,
      order: `/orders/${docId}`,
      intrestedDonor: `/donors/${docId}/view`,
      branches: `/donors/${docId}`,
    }
    return obj[page]
  }

  const columns = [
    { field: "body", headerName: "Message", flex: 1 },
    { field: "notiType", headerName: "Type", flex: 1 },
    { field: "branch", headerName: "Branch", flex: 1 },
    {
      field: "dateCreated",
      headerName: "Date",
      flex: 1,
      renderCell: params => {
        return <div>{new Date(params?.row?.dateCreated).toLocaleString()}</div>
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: params => {
        if (mapLinks(params?.row?.page, params?.row?.docId)) {
          return (
            <div>
              <Link to={mapLinks(params?.row?.page, params?.row?.docId)}>
                <Button color="primary">View</Button>
              </Link>
            </div>
          )
        }
      },
    },
  ]

  const getListCount = async () => {
    try {
      const res = await httpClient.get(
        "/api/count/testNoti?to=CYROBANK@test.com&sort=-dateCreated"
      )
      setNotificationsCount(res.data?.result || 0)
    } catch (error) {
      console.log(error?.message)
    }
  }

  const getList = async () => {
    try {
      const res = await httpClient.get(
        `/api/testNoti?to=CYROBANK@test.com&sort=-dateCreated&limit=${TABLE_LIMIT}&skip=${
          TABLE_LIMIT * (page - 1)
        }`
      )
      if (res?.data?.result && res?.data?.result?.length > 0) {
        notifications
        setNotifications(res.data.result)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    getListCount()
  }, [])

  useEffect(() => {
    getList()
  }, [page])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Notifications List</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">All Notifications</CardTitle>

                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <DataGrid
                          rows={notifications}
                          columns={columns}
                          rowCount={notificationsCount}
                          onPaginationModelChange={model => {
                            setPage(model.page + 1)
                          }}
                          pagination
                          paginationMode="server"
                          initialState={{
                            pagination: {
                              paginationModel: {
                                page: page - 1,
                                pageSize: TABLE_LIMIT,
                              },
                            },
                          }}
                          // pageSizeOptions={[5, 10]}
                          getRowId={row => row._id}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

List.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(List)
