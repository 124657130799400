import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Label,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { httpClient } from "helpers/api_helper"

const Add = props => {
  const [branches, setBranches] = useState([])

  const getBranches = async () => {
    try {
      const res = await httpClient.get("/api/branches?fields=name")

      if (res?.data?.result && res?.data?.result?.length > 0) {
        const localBrancheIds = (
          JSON.parse(localStorage.getItem("authUser")) || []
        )?.roles?.map(x => x.idOrg)
        setBranches(
          (res.data.result || []).filter(x => localBrancheIds.includes(x._id))
        )
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    getBranches()
  }, [])

  const handleSubmit = async (event, values) => {
    console.log({ values })
    try {
      await httpClient.post("api/pincost", values)
      alert("Pincode cost added successfully")
      props.history.push("/shipping-cost")
    } catch (error) {
      console.log("error", error)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Shipping cost</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Create shipping cost</h6>
                <Link style={{ float: "right" }} to="/shipping-cost">
                  Shipping cost List
                </Link>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={handleSubmit}
                    >
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="title">Pin code</Label>
                            <AvField
                              name="pincode"
                              placeholder="Enter pin code"
                              type="text"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                // maxLength: 6,
                              }}
                              id="title"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Select Branch
                            </Label>
                            <AvField
                              name="idOrg"
                              type="select"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                            >
                              <option value="">Select Branch</option>
                              {branches &&
                                branches?.length > 0 &&
                                branches.map(el => {
                                  return (
                                    <option value={el._id}>{el.name}</option>
                                  )
                                })}
                            </AvField>
                          </FormGroup>
                        </Col>

                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="type">Cost</Label>
                            <AvField
                              name="cost"
                              placeholder="Enter cost"
                              type="text"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="type"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="text-center">
                        <Button color="primary" type="submit">
                          Submit form
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

Add.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Add)
