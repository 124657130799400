import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"
import { httpClient } from "helpers/api_helper"
import { VALUE_TYPES } from "./edit"

const Add = props => {
  const [fieldKeys, setFieldKeys] = useState([])

  const getFieldsKeys = async () => {
    try {
      const res = await httpClient.get("/api/fieldKeys")

      if (res?.data?.result && res?.data?.result?.length > 0) {
        setFieldKeys(res.data.result)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }
  const handleSubmit = async (event, values) => {
    try {
      values.options = values.options ? values.options.split("|") : []
      await httpClient.post("/api/fieldOptions", values)
      alert("field option added successfully")
      props.history.push("/field-options")
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    getFieldsKeys()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Field Options</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Field Options</h6>
                <Link style={{ float: "right" }} to="/field-options">
                  Field options List
                </Link>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={handleSubmit}
                      model={{
                        isGroupMaster: false,
                        isArray: false,
                        require: false,
                        isOptionFixed: false,
                        showFilter: false,
                        showSort: false,
                        minLength: -1,
                        maxLength: -1,
                      }}
                    >
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="title">Field Title</Label>
                            <AvField
                              name="title"
                              placeholder="Enter Field Title"
                              type="text"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="title"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="key">Field key</Label>
                            <AvField
                              name="key"
                              placeholder="Enter Field Key"
                              type="text"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="key"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="key">Master group</Label>
                            <AvField
                              name="idMaster"
                              type="select"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="idMaster"
                            >
                              <option value="">Select Master</option>
                              {fieldKeys &&
                                fieldKeys?.map((el, index) => {
                                  return (
                                    <option key={index} value={el.key}>
                                      {el.title}
                                    </option>
                                  )
                                })}
                            </AvField>
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="isGroupMaster">
                              Is Group Master ?
                            </Label>
                            <AvRadioGroup
                              id="isGroupMaster"
                              inline
                              name={"isGroupMaster"}
                            >
                              <AvRadio label="Yes" value={true} />
                              <AvRadio label="NO" value={false} />
                            </AvRadioGroup>
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="group">Group</Label>
                            <AvField
                              name="group"
                              placeholder="Enter group"
                              type="text"
                              className="form-control"
                              id="group"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="inx">Index</Label>
                            <AvField
                              name="inx"
                              placeholder="Enter index"
                              type="number"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="inx"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="description">description</Label>
                            <AvField
                              name="description"
                              placeholder="Enter description"
                              type="text"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="description"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="hint">Hint</Label>
                            <AvField
                              name="hint"
                              placeholder="Enter Hint"
                              type="text"
                              className="form-control"
                              id="hint"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="isArray">Is Array?</Label>
                            <AvRadioGroup id="isArray" inline name={"isArray"}>
                              <AvRadio label="Yes" value={true} />
                              <AvRadio label="NO" value={false} />
                            </AvRadioGroup>
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="prefix">Prefix</Label>
                            <AvField
                              name="prefix"
                              placeholder="Enter prefix"
                              type="text"
                              className="form-control"
                              id="prefix"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="description">Suffix</Label>
                            <AvField
                              name="suffix"
                              placeholder="Enter suffix"
                              type="text"
                              className="form-control"
                              id="suffix"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="require">Is Array?</Label>
                            <AvRadioGroup id="require" inline name={"require"}>
                              <AvRadio label="Yes" value={true} />
                              <AvRadio label="NO" value={false} />
                            </AvRadioGroup>
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="isOptionFixed">
                              is Option Fixed?
                            </Label>
                            <AvRadioGroup
                              id="isOptionFixed"
                              inline
                              name={"isOptionFixed"}
                            >
                              <AvRadio label="Yes" value={true} />
                              <AvRadio label="NO" value={false} />
                            </AvRadioGroup>
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="showFilter">Show Filter</Label>
                            <AvRadioGroup
                              id="showFilter"
                              inline
                              name={"showFilter"}
                            >
                              <AvRadio label="Yes" value={true} />
                              <AvRadio label="NO" value={false} />
                            </AvRadioGroup>
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="showSort">Show Sort</Label>
                            <AvRadioGroup
                              id="showSort"
                              inline
                              name={"showSort"}
                            >
                              <AvRadio label="Yes" value={true} />
                              <AvRadio label="NO" value={false} />
                            </AvRadioGroup>
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="valueType">Value Type</Label>
                            <AvField
                              name="valueType"
                              placeholder="Enter min length"
                              type="select"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="valueType"
                            >
                              <option value="">Select value type</option>
                              {VALUE_TYPES.map(valueType => (
                                <option value={valueType} key={valueType}>
                                  {valueType}
                                </option>
                              ))}
                            </AvField>
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="minLength">Min Length</Label>
                            <AvField
                              name="minLength"
                              placeholder="Enter min length"
                              type="text"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="minLength"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="maxLength">Max Length</Label>
                            <AvField
                              name="maxLength"
                              placeholder="Enter max length"
                              type="text"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="maxLength"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="options">
                              Options (Enter | seperated string)
                            </Label>
                            <AvField
                              name="options"
                              placeholder="Enter options"
                              type="textarea"
                              className="form-control"
                              id="options"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="text-center">
                        <Button color="primary" type="submit">
                          Submit form
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

Add.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Add)
