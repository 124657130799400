export const getIndexTitleDescription = str => {
  const indexRegex = /inx:\s*(-?\d+)/
  const titleRegex = /Title:\s*([^]+?)(?=\s+(?:inx|Description|Input|cusup|$))/
  const descriptionRegex =
    /Description:\s*([^]+?)(?=\s+(?:inx|Title|Input|cusup|$))/
  const inputRegex = /Input:\s*([^]+?)(?=\s+(?:inx|Title|Description|cusup|$))/
  const canUserUpdateRegex =
    /cusup:\s*([^]+?)(?=\s+(?:inx|Title|Description|Input|$))/
  str = str.replace(/\n/g, " ") + " "
  const index = indexRegex.exec(str)?.[1]
  const title = titleRegex.exec(str)?.[1]
  const description = descriptionRegex.exec(str)?.[1]
  const input = inputRegex.exec(str)?.[1]
  const cusup = canUserUpdateRegex.exec(str)?.[1] || "true"
  const temp = { index, title, description, input, cusup }
  return temp
}

export const TABLE_LIMIT = 10
