import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardTitle, CardBody } from "reactstrap"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { httpClient } from "helpers/api_helper"
import { getIndexTitleDescription } from "helpers/common"

const View = props => {
  const [formData, setformData] = useState({})

  const getformData = async () => {
    try {
      const res = await httpClient.get(`/api/orders/${props.match.params.id}`)
      if (res?.data?.result && res?.data?.result?.length != 0) {
        setformData(res.data.result[0])
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    getformData()
  }, [])

  const [open, setOpen] = useState("")
  const toggle = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>View Orders</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={12}>
                <h6 className="page-title">Order Details</h6>
                <Link style={{ float: "right" }} to="/orders">
                  Orders List
                </Link>
              </Col>
            </Row>
            <Card>
              <CardTitle>
                Order Details
                {formData?.idAuthor && (
                  <Link
                    style={{ float: "right" }}
                    to={`/customers/${formData?.idAuthor}`}
                  >
                    Customer Details
                  </Link>
                )}
              </CardTitle>
              <CardBody>
                <Row>
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Order Amount</td>
                        <td className="bold">{formData?.total}</td>
                      </tr>
                      <tr>
                        <td>Discount</td>
                        <td className="bold">{formData?.discount}</td>
                      </tr>
                      <tr>
                        <td>Offer</td>
                        <td className="bold">{formData?.offer}</td>
                      </tr>
                      <tr>
                        <td>Payment Mode</td>
                        <td className="bold">{formData?.paymentMode}</td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td className="bold">
                          {new Date(formData?.dateCreated).toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h5>Order Items</h5>
                  {formData?.orderItems?.map(el => {
                    return (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id={el._id}
                        >
                          <Typography>Product ID</Typography>
                          <Link
                            style={{ position: "absolute", right: "49px" }}
                            to={`/donors/${el.productId}/view?page_from=order`}
                          >
                            View Details
                          </Link>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <table class="table">
                              <tbody>
                                <tr>
                                  <td>Price</td>
                                  <td className="bold">{el?.price}</td>
                                </tr>
                                <tr>
                                  <td>Discount</td>
                                  <td className="bold">{el?.discount}</td>
                                </tr>
                                <tr>
                                  <td>Quantity</td>
                                  <td className="bold">{el?.quantity}</td>
                                </tr>
                                <tr>
                                  <td>Type</td>
                                  <td className="bold">{el?.type}</td>
                                </tr>
                              </tbody>
                            </table>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardTitle>Donor Details</CardTitle>
              <CardBody>
                <Row>
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td className="bold">
                          {formData?.transaction?.customer?.name}
                        </td>
                      </tr>
                      <tr>
                        <td>Mobile No.</td>
                        <td className="bold">
                          {formData?.transaction?.customer?.contact}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardTitle>IVF Center Details</CardTitle>
              <CardBody>
                <Row>
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td className="bold">{formData?.address?.name}</td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td className="bold">{formData?.address?.phone}</td>
                      </tr>
                      <tr>
                        <td>House No</td>
                        <td className="bold">{formData?.address?.houseNo}</td>
                      </tr>
                      <tr>
                        <td>City</td>
                        <td className="bold">{formData?.address?.city}</td>
                      </tr>
                      <tr>
                        <td>State</td>
                        <td className="bold">{formData?.address?.state}</td>
                      </tr>
                      <tr>
                        <td>Street</td>
                        <td className="bold">
                          {formData?.address?.streetOne}{" "}
                          {formData?.address?.streetTwo}
                        </td>
                      </tr>
                      <tr>
                        <td>LandMark</td>
                        <td className="bold">{formData?.address?.landmark}</td>
                      </tr>
                      <tr>
                        <td>Pincode</td>
                        <td className="bold">{formData?.address?.pincode}</td>
                      </tr>
                      <tr>
                        <td>Registration number</td>
                        <td className="bold">{formData?.address?.uniqueId}</td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardTitle>Order Status</CardTitle>
              <CardBody>
                <Row>
                  <p>
                    Current Status: <strong>{formData.statusStr}</strong>
                  </p>
                  <table class="table">
                    <tbody>
                      {formData?.statusMap?.map(el => {
                        return (
                          <tr key={el._id}>
                            <td>status</td>
                            <td className="bold">
                              {getIndexTitleDescription(el.status)?.title}
                            </td>
                            <td>Date</td>
                            <td className="bold">
                              {new Date(el.dateCreated).toLocaleString()}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

View.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(View)
