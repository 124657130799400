import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  FormGroup,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { DataGrid } from "@mui/x-data-grid"
import { httpClient } from "helpers/api_helper"
import { TABLE_LIMIT } from "helpers/common"

const List = props => {
  const [branches, setBranches] = useState([])
  const [branchesCount, setBranchesCount] = useState(0)
  const [page, setPage] = useState(1)

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "contactInfo.mobile",
      headerName: "Mobile No.",
      flex: 1,
      valueGetter: params => params?.row?.contactInfo?.mobile,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
    },
    {
      field: "state",
      headerName: "State",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: params => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Link to={`/branches/${params.row._id}`}>
              <Button color="primary">Edit</Button>
            </Link>
          </div>
        )
      },
    },
  ]

  const getBranchesCount = async () => {
    try {
      const res = await httpClient.get("/api/count/branches")
      setBranchesCount(res.data?.result)
    } catch (error) {
      console.log(error?.message)
    }
  }

  const getBranches = async () => {
    try {
      const res = await httpClient.get(
        `/api/branches?limit=${TABLE_LIMIT}&skip=${TABLE_LIMIT * (page - 1)}`
      )

      if (res?.data?.result && res?.data?.result?.length > 0) {
        setBranches(res.data.result)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    getBranchesCount()
  }, [])

  useEffect(() => {
    getBranches()
  }, [page])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Branches</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      All Branches
                      <Link style={{ float: "right" }} to="/branches/add">
                        Create
                      </Link>
                    </CardTitle>

                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <DataGrid
                          rows={branches}
                          columns={columns}
                          rowCount={branchesCount}
                          onPaginationModelChange={model => {
                            setPage(model.page + 1)
                          }}
                          pagination
                          paginationMode="server"
                          initialState={{
                            pagination: {
                              paginationModel: {
                                page: page - 1,
                                pageSize: TABLE_LIMIT,
                              },
                            },
                          }}
                          // pageSizeOptions={[5, 10]}
                          getRowId={row => row._id}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

List.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(List)
