import axios from "axios"
// import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
// const token = accessToken

//apply base url for axios
const API_URL = "https://backend.awsone.7sperm.com"

const axiosApi = axios.create({
  baseURL: API_URL,
})

// axiosApi.defaults.headers.common["Authorization"] = token
// axiosApi.defaults.headers.common["x-access-token"] = token

axiosApi.interceptors.request.use(async function (config) {
  axios.defaults.timeout = 35000
  let token =
    localStorage.getItem("authUser") &&
    JSON.parse(localStorage.getItem("authUser"))?.token

  if (token) {
    config.headers.common["Authorization"] = token
    config.headers.common["x-access-token"] = token
  }
  return config
})

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("authUser")
      window.location.href = "/login"
      return
    }
    return Promise.reject(error)
  }
)

export async function get(url, config = {}) {
  try {
    const res = await axiosApi.get(url, { ...config })
    return res.data
  } catch (error) {
    console.log("error", error)
  }
}

export async function post(url, data, config = {}) {
  try {
    const res = await axiosApi.post(url, { ...data }, { ...config })
    return res.data
  } catch (error) {
    console.log("error", error)
  }
}

export async function put(url, data, config = {}) {
  try {
    const res = await axiosApi.put(url, { ...data }, { ...config })
    return res.data
  } catch (error) {
    console.log("error", error)
  }
}

export async function del(url, config = {}) {
  try {
    const res = await axiosApi.delete(url, { ...config })
    return res.data
  } catch (error) {
    console.log("error", error)
  }
}

export const httpClient = axiosApi
