import PropTypes from 'prop-types'
import React,{useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap"
import { Link } from "react-router-dom"

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png";
import servicesIcon2 from "../../assets/images/services-icon/02.png";
import servicesIcon3 from "../../assets/images/services-icon/03.png";
import servicesIcon4 from "../../assets/images/services-icon/04.png";


// Charts
import LineAreaChart from "../AllCharts/apex/lineareachart";
import "chartist/dist/scss/chartist.scss";

import { DateRange, DateRangeEditor } from 'material-mui-date-range-picker';
import { ThemeProvider, StyledEngineProvider, useTheme } from '@mui/material/styles';



//i18n
import { withTranslation } from "react-i18next"
import { httpClient } from 'helpers/api_helper';
import { DateRangePicker } from "mui-daterange-picker";
import moment from 'moment';

const Dashboard = props => {

  const [menu, setMenu] = useState(false)
  const [counts, setCounts] = useState({
    orders:null,
    revenue:null,
    users:null,
    donors:null
  })
  const [earnings, setEarnings] = useState([])
  const [recentDonors, setRecentDonors] = useState([])
  const [staffList, setStaffList] = useState([])
  const [recentOrders, setRecentOrders] = useState([])
  const [branches,setBranches] = useState([]);

  const [idOrg,setIdOrg] = useState("");


  const [dateRange, setDateRange] = useState({
    startDate : null,
    endDate : null
  });

  const [dateRange2, setDateRange2] = useState({
    startDate : "",
    endDate : ""
  });


  const changeDateRange = (range) => {
    console.log("range",range)
    const range2 = {
      startDate: range.startDate ? moment(range.startDate).unix() : '',
      endDate: range.endDate ? moment(range.endDate).unix() : ''
    }
    console.log("range",range2)
    setDateRange(range)
    setDateRange2(range2)
  }



  const getOrdersCount = async () => {
    try {
      const res = await httpClient.get(`/api/count/orders?dateStart=${dateRange2.startDate}&dateEnd=${dateRange2.endDate}&idOrg=${idOrg}`)

      if(res?.data?.result){
        setCounts((prev) => ({...prev,orders:res.data.result}))
      }
    } catch (error) {
      setCounts((prev) => ({...prev,orders:null}))
    }
  }

  const getRevenueCount = async () => {
    try {
      const res = await httpClient.get(`/api/count/statsforui?action=order&dateStart=${dateRange2.startDate}&dateEnd=${dateRange2.endDate}&idOrg=${idOrg}`)

      if(res?.data?.result && res?.data?.result?.length > 0){
        setCounts((prev) => ({...prev,revenue:res.data.result?.[0]?.data}))
      }
      
    } catch (error) {
      setCounts((prev) => ({...prev,revenue:null}))
    }
  }

  const getUsersCount = async () => {
    try {

      const res = await httpClient.get(`/api/count/user?role=seeker&dateStart=${dateRange2.startDate}&dateEnd=${dateRange2.endDate}&idOrg=${idOrg}`)

      if(res?.data?.result){
        setCounts((prev) => ({...prev,users:res.data.result}))
      }
      
    } catch (error) {
      setCounts((prev) => ({...prev,users:null}))
    }
  }

  const getDonorsCount = async () => {
    try {

      const res = await httpClient.get(`/api/count/user?role=donor&dateStart=${dateRange2.startDate}&dateEnd=${dateRange2.endDate}&idOrg=${idOrg}`)

      if(res?.data?.result){
        setCounts((prev) => ({...prev,donors:res.data.result}))
      }
      
    } catch (error) {
      setCounts((prev) => ({...prev,donors:null}))
    }
  }

  const getEarningGraph = async () => {
    try {

      const res = await httpClient.get('/api/statsforui?action=order&statType=day_all')

      if(res?.data?.result){
        setEarnings(res?.data?.result)
      }
      
    } catch (error) {
      setEarnings([])
    }
  }

  const getRecentDonors = async () => {
    try {

      const res = await httpClient.get('/api/intrestedDonor?populate=idAuthor')

      if(res?.data?.result && res?.data?.result?.length > 0){
        setRecentDonors(res?.data?.result)
      }
      
    } catch (error) {
      setRecentDonors([])
    }
  }

  const getStaffList = async () => {
    try {

      const res = await httpClient.get('/api/staff?populate=idOrg')

      if(res?.data?.result && res?.data?.result?.length > 0){
        setStaffList(res?.data?.result)
      }
      
    } catch (error) {
      setStaffList([])
    }
  }

  const RecentOrders = async () => {
    try {

      const res = await httpClient.get('/api/orders')

      if(res?.data?.result && res?.data?.result?.length > 0){
        setRecentOrders(res?.data?.result)
      }
      
    } catch (error) {
      setRecentOrders([])
    }
  }

  const toggle = () => {
    setMenu(!menu)
  }

  const getBranches = async () => {
    try {
      const res = await httpClient.get('/api/branches')

      if(res?.data?.result && res?.data?.result?.length > 0){
        setBranches(res.data.result)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    getBranches()
  },[])

  useEffect( async ()=>{
    await Promise.all([
      getOrdersCount(),
      getRevenueCount(),
      getUsersCount(),
      getDonorsCount()
    ])
  },[dateRange2.startDate,dateRange2.endDate,idOrg])

  useEffect( async ()=>{
    await Promise.all([
      getRecentDonors(),
      getStaffList(),
      RecentOrders(),
    ])
  },[])

  useEffect(() => {
    getEarningGraph()
  },[])

  
  const theme = useTheme();

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>7SpermDonor</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={3}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to 7SpermDonor Dashboard</li>
                </ol>
              </Col>
              <Col md={9} style={{
                display:'flex',
                justifyContent:'space-evenly'
              }}>
                <div>
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                      <div className='date-app'>
                        <DateRangeEditor 
                          dateRange={dateRange} 
                          onChange={changeDateRange} />
                      </div>
                    </ThemeProvider>
                  </StyledEngineProvider>
                </div>
                <div>
                      <select 
                        className="form-control"
                        onChange={(e)=>{
                          setIdOrg(e.target.value)
                        }}
                      >
                        <option key={0} value={""}>Select Branch</option>
                        {
                          branches && branches?.length > 0 &&
                          branches.map((el,i)=>{
                            return <option key={i+1} value={el.idOrg}>{el.name}</option>
                          })
                        }
                      </select>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Orders
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {counts.orders}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Revenue
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {counts.revenue}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon3} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Users
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {counts.users}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon4} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Donors
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {counts.donors}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Earning</h4>
                  <Row>
                    <Col lg={12}>
                      <div>
                        <LineAreaChart />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Recent Donors</h4>
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Location</th>
                          <th scope="col" colSpan="2">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          recentDonors.length > 0 &&
                          recentDonors.map((item,index)=>{
                            return <tr key={index}>
                              <td>
                                <div>
                                  {item?.idAuthor?.name}
                                </div>
                              </td>
                              <td>{item?.idAuthor?.address?.city}</td>
                              <td>
                                <span className="badge bg-success">
                                  {item?.statusStr}
                                </span>
                              </td>
                              <td>
                                <div>
                                  <Link to={`/donors/${item?._id}/view`} className="btn btn-primary btn-sm">
                                    View
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Staff List</h4>
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          staffList.length > 0 &&
                          staffList.map((item,index)=>{
                            return <tr key={index}>
                                    <td>
                                      <div>
                                        {item?.name}
                                      </div>
                                    </td>
                                    <td>
                                        {item.email}
                                    </td>
                                    <td>
                                      <div>
                                        <Link to={`/staffs/${item?._id}`} className="btn btn-primary btn-sm">
                                          View
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Recent Orders</h4>
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Order Id</th>
                          <th scope="col">Price</th>
                          <th scope="col">Discount</th>
                          <th scope="col">Payment Mode</th>
                          <th scope="col">
                            Status
                          </th>
                          <th>
                            City
                          </th>
                        </tr>
                      </thead>
                      <tbody>  
                        {
                          recentOrders.length > 0 && recentOrders.map((item,index)=>{
                              return <tr key={index}>
                                        <th scope="row">{item?._id}</th>
                                        <td>
                                          {item?.total}
                                        </td>
                                        <td>{item?.discount}</td>
                                        <td>{item?.paymentMode}</td>
                                        <td>
                                          <span className="badge bg-success">
                                            {item?.statusStr}
                                          </span>
                                        </td>
                                        <td>
                                         {item?.address?.city}, {item?.address?.state}
                                        </td>
                                        <td>
                                          <div>
                                            <Link to={`/orders/${item?._id}`} className="btn btn-primary btn-sm">
                                              View
                                            </Link>
                                          </div>
                                        </td>
                                      </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>

    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)
