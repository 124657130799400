import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  FormGroup,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { httpClient } from "helpers/api_helper"
import { DataGrid } from "@mui/x-data-grid"
import { TABLE_LIMIT } from "helpers/common"

const List = props => {
  const [staffs, setStaffs] = useState([])
  const [staffsCount, setStaffsCount] = useState(0)
  const [page, setPage] = useState(1)

  const getStaffsCount = async () => {
    try {
      const res = await httpClient.get("/api/count/user?role=staff")
      setStaffsCount(res.data?.result || 0)
    } catch (error) {
      console.log(error?.message)
    }
  }

  const getStaffs = async () => {
    try {
      const res = await httpClient.get(
        `/api/user?role=staff&populate=idOrg&limit=${TABLE_LIMIT}&skip=${
          TABLE_LIMIT * (page - 1)
        }`
      )
      if (res?.data?.result && res?.data?.result?.length > 0) {
        setStaffs(res.data.result)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    getStaffsCount()
  }, [])

  useEffect(() => {
    getStaffs()
  }, [page])

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: params => {
        return (
          <div>
            {params.row?._id && (
              <Link to={`/staffs/${params.row?._id}`}>
                <Button color="primary">Edit</Button>
              </Link>
            )}
          </div>
        )
      },
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Staffs List</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      All Staffs
                      <Link style={{ float: "right" }} to="/staffs/add">
                        Create
                      </Link>
                    </CardTitle>

                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <DataGrid
                          rows={staffs}
                          columns={columns}
                          rowCount={staffsCount}
                          onPaginationModelChange={model => {
                            setPage(model.page + 1)
                          }}
                          pagination
                          paginationMode="server"
                          initialState={{
                            pagination: {
                              paginationModel: {
                                page: page - 1,
                                pageSize: TABLE_LIMIT,
                              },
                            },
                          }}
                          // pageSizeOptions={[5, 10]}
                          getRowId={row => row._id}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

List.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(List)
