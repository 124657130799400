import React, { Component } from 'react';
import ChartistGraph from 'react-chartist';

class lineareachart extends Component {
    render() {
        var lineChartData = {
            labels: ['1st Jul 23', "2nd Jul 23", "3 Jul 23", "4 Jul 23", "5 Jul 23", "6 Jul 23", "7 Jul 23", "8 Jul 23"],
            series: [
                [10,100,1000,2500,3000,5000]
            ]
        };
        var lineChartOptions = {
            low: 0,
            showArea: false,
        }
        return (
            <React.Fragment>
                <ChartistGraph data={lineChartData} style={{ height: "300px" }} options={lineChartOptions} type={'Line'} />
            </React.Fragment>
        );
    }
}

export default lineareachart;