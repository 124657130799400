import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"
import { httpClient } from "helpers/api_helper"

const Add = props => {
  const [inputFields, setInputFields] = useState([])
  const [loading, setLoading] = useState(false)

  const getinputFields = async () => {
    try {
      const res = await httpClient.get("/api/fieldOptions?limit=200")
      if (res?.data?.result && res?.data?.result?.length != 0) {
        setInputFields(res.data.result)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log("error", error)
    }
  }

  useEffect(() => {
    setLoading(true)
    getinputFields()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Create Donors</title>
        </MetaTags>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center p-5">
            <Spinner className="" color="primary" />
          </div>
        ) : (
          <Container fluid>
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col md={8}>
                  <h6 className="page-title">Create Donor</h6>
                  <Link style={{ float: "right" }} to="/donors">
                    Donors List
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <Card>
                    <CardBody>
                      <AvForm className="needs-validation">
                        <Row>
                          {inputFields &&
                            inputFields.length != 0 &&
                            inputFields.map((item, index) => {
                              if (
                                item.hasOwnProperty("options") ||
                                item.isArray
                              ) {
                                return (
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor={item._id}>
                                        {item.title}
                                      </Label>
                                      <AvField
                                        name={item.key}
                                        placeholder={item.title}
                                        type="select"
                                        className="form-control"
                                        validate={{
                                          required: { value: item.require },
                                        }}
                                        id={item._id}
                                      >
                                        <option value="">
                                          -----Select-----
                                        </option>
                                        {item?.options?.map(el => {
                                          return (
                                            <option value={el}>{el}</option>
                                          )
                                        })}
                                      </AvField>
                                    </FormGroup>
                                  </Col>
                                )
                              } else if (item.valueType == "boolean") {
                                return (
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <AvRadioGroup
                                        inline
                                        name={item.key}
                                        label={item.title}
                                      >
                                        <AvRadio label="Yes" value={true} />
                                        <AvRadio label="NO" value={false} />
                                      </AvRadioGroup>
                                    </FormGroup>
                                  </Col>
                                )
                              } else if (
                                item.valueType == "number" ||
                                "string"
                              ) {
                                return (
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor={item._id}>
                                        {item.title}
                                      </Label>
                                      <AvField
                                        name={item.key}
                                        placeholder={item.title}
                                        type="text"
                                        className="form-control"
                                        validate={{
                                          required: { value: item.require },
                                        }}
                                        id={item._id}
                                      />
                                    </FormGroup>
                                  </Col>
                                )
                              }
                            })}
                        </Row>
                        <div className="text-center">
                          <Button color="primary" type="submit">
                            Submit form
                          </Button>
                        </div>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

Add.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Add)
