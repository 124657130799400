import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  FormGroup,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { DataGrid } from "@mui/x-data-grid"
import { httpClient } from "helpers/api_helper"
import { getIndexTitleDescription, TABLE_LIMIT } from "helpers/common"

const List = props => {
  const [orders, setOrders] = useState([])
  const [stages, setStages] = useState([])
  const [ordersCount, setOrdersCount] = useState(0)
  const [page, setPage] = useState(1)

  console.log("stages", stages)

  const columns = [
    {
      field: "_id",
      headerName: "OrderId",
      flex: 1,
    },
    {
      field: "total",
      headerName: "Price",
      flex: 1,
    },
    {
      field: "discount",
      headerName: "Discount",
      flex: 1,
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode",
      flex: 1,
    },
    {
      field: "statusStr",
      headerName: "Status",
      flex: 1,
      renderCell: params => {
        let currrentIndexWithString = stages.find(
          el => el.index == params?.row?.status
        )
        let currrentIndex = currrentIndexWithString?.index
        currrentIndex = currrentIndex ? parseInt(currrentIndex) : null
        let nextIndex
        if (currrentIndex >= 0) {
          nextIndex = stages.find(
            el => el.index > currrentIndex && el.cusup != "false"
          )?.index
        }

        if (currrentIndexWithString?.title == "Payment Sucessfull") {
          nextIndex = stages.find(
            el => el.index > nextIndex && el.cusup != "false"
          )?.index
        }

        return (
          <div>
            <select
              className="form-control mb-3"
              value={params?.row?.status}
              onChange={event => handleChange(event, params.row)}
            >
              <option value="">-- select status --</option>
              {stages?.map((el, index) => {
                return (
                  <option
                    key={index}
                    value={el.index}
                    disabled={
                      el.index == params?.row?.status || el.index == nextIndex
                        ? false
                        : true
                    }
                  >
                    {el.title}
                  </option>
                )
              })}
            </select>
          </div>
        )
      },
    },
    {
      field: "address_city",
      headerName: "City",
      flex: 1,
      valueGetter: params =>
        `${params?.row?.address?.city}, ${params?.row?.address?.state}`,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: params => {
        return (
          <div>
            {
              <Link to={`/orders/${params.row?._id}`}>
                <Button color="success">View</Button>
              </Link>
            }
          </div>
        )
      },
    },
  ]

  const handleChange = async (event, data) => {
    let payload = stages.find(el => el.index == event.target.value)
    if (event.target.value) {
      try {
        const res = await httpClient.put(`/api/orders/${data._id}`, payload)
        console.log("res", res)
        if (res?.data?.result && res?.data?.result?.length > 0) {
          getOrderStages()
          getOrders()
        }
      } catch (error) {
        console.log(error.message)
        alert(error?.response?.data?.message)
      }
    }
  }

  const getOrdersCount = async () => {
    try {
      const res = await httpClient.get("/api/count/orders")
      setOrdersCount(res.data?.result || 0)
    } catch (error) {
      console.log(error?.message)
    }
  }

  const getOrders = async () => {
    try {
      const res = await httpClient.get(
        `/api/orders?sort=-dateCreated&limit=${TABLE_LIMIT}&skip=${
          TABLE_LIMIT * (page - 1)
        }`
      )
      if (res?.data?.result && res?.data?.result?.length > 0) {
        setOrders(res.data.result)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  const getOrderStages = async () => {
    try {
      const res = await httpClient.get("/api/fieldKeys?key=orderStages")
      console.log(res)
      if (res?.data?.result && res?.data?.result?.length > 0) {
        const stages = []

        res.data.result?.[0]?.options?.map(el => {
          stages.push(getIndexTitleDescription(el))
        })
        setStages(stages)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    getOrderStages()
    getOrdersCount()
  }, [])

  useEffect(() => {
    getOrders()
  }, [page])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Orders List</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      All Orders
                      <Link style={{ float: "right" }} to="/orders/create">
                        Create
                      </Link>
                    </CardTitle>

                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <DataGrid
                          rows={orders}
                          columns={columns}
                          rowCount={ordersCount}
                          onPaginationModelChange={model => {
                            setPage(model.page + 1)
                          }}
                          pagination
                          paginationMode="server"
                          initialState={{
                            pagination: {
                              paginationModel: {
                                page: page - 1,
                                pageSize: TABLE_LIMIT,
                              },
                            },
                          }}
                          // pageSizeOptions={[5, 10]}
                          getRowId={row => row._id}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

List.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(List)
