import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  FormGroup,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { httpClient } from "helpers/api_helper"
import { DataGrid } from "@mui/x-data-grid"
import { TABLE_LIMIT } from "helpers/common"

const List = props => {
  const [fieldKeys, setFieldKeys] = useState([])
  const [fieldOptionsCount, setFieldOptionsCount] = useState(0)
  const [page, setPage] = useState(1)

  const columns = [
    { field: "title", headerName: "Title", flex: 1 },
    {
      field: "idMaster",
      headerName: "Master",
      flex: 1,
      renderCell: params =>
        params?.row?.idMaster
          ?.replace(/([A-Z]+)/g, " $1")
          ?.replace(/([A-Z][a-z])/g, " $1"),
    },
    { field: "key", headerName: "Key", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: params => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Link to={`/field-options/${params.row._id}`}>
              <Button color="primary">Edit</Button>
            </Link>
            <Button
              color="danger"
              onClick={() => {
                deleteData(params.row._id)
              }}
            >
              Delete
            </Button>
          </div>
        )
      },
    },
  ]

  const getFieldsKeysCounts = async () => {
    try {
      const res = await httpClient.get("/api/count/fieldOptions")
      setFieldOptionsCount(res.data?.result || 0)
    } catch (error) {
      console.log(error?.message)
    }
  }

  const getFieldsKeys = async () => {
    try {
      const res = await httpClient.get(
        `/api/fieldOptions?limit=${TABLE_LIMIT}&skip=${
          TABLE_LIMIT * (page - 1)
        }`
      )

      if (res?.data?.result && res?.data?.result?.length > 0) {
        setFieldKeys(res.data.result)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  const deleteData = async id => {
    try {
      if (confirm("Are your sure? you want to delete it.")) {
        await httpClient.delete(`/api/fieldOptions/${id}`)
        getFieldsKeys()
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    getFieldsKeysCounts()
  }, [])

  useEffect(() => {
    getFieldsKeys()
  }, [page])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Field options list</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      All Fields keys
                      <Link style={{ float: "right" }} to="/field-options/add">
                        Create
                      </Link>
                    </CardTitle>

                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <DataGrid
                          rows={fieldKeys}
                          columns={columns}
                          rowCount={fieldOptionsCount}
                          onPaginationModelChange={model => {
                            setPage(model.page + 1)
                          }}
                          pagination
                          paginationMode="server"
                          initialState={{
                            pagination: {
                              paginationModel: {
                                page: page - 1,
                                pageSize: TABLE_LIMIT,
                              },
                            },
                          }}
                          // pageSizeOptions={[5, 10]}
                          getRowId={row => row._id}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

List.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(List)
