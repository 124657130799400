import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardTitle, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { httpClient } from "helpers/api_helper"

const View = props => {
  const [inputFields, setInputFields] = useState([])
  const [formData, setformData] = useState({})

  const getinputFields = async () => {
    try {
      const res = await httpClient.get("/api/fieldOptions?limit=200")
      if (res?.data?.result && res?.data?.result?.length != 0) {
        setInputFields(res.data.result)
        getformData()
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const getformData = async () => {
    try {
      const query = new URLSearchParams(props.location.search)
      let checkPage = query.get("page_from")
      let res
      if (checkPage == "order") {
        res = await httpClient.get(
          `/api/intrestedDonor?transaction=${props.match.params.id}&populate=transaction,idAuthor`
        )
      } else {
        res = await httpClient.get(
          `/api/intrestedDonor/${props.match.params.id}?populate=transaction,idAuthor`
        )
      }

      if (res?.data?.result && res?.data?.result?.length != 0) {
        setformData(res.data.result[0])
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    getinputFields()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>View Donors</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={12}>
                <h6 className="page-title">
                  Donor's Detail ({formData?.transaction?.nam})
                </h6>
                <p>
                  <strong>Status:</strong>{" "}
                  {formData?.statusStr ? formData?.statusStr : ""}
                </p>
                <Link style={{ float: "right" }} to="/donors">
                  Donors List
                </Link>
              </Col>
            </Row>
            <Card>
              <CardTitle tag="h5">
                Personal Details
                {formData?.idAuthor?._id && (
                  <Link
                    style={{ float: "right" }}
                    to={`/customers/${formData?.idAuthor?._id}`}
                  >
                    View Customer Details
                  </Link>
                )}
              </CardTitle>
              {formData?.transaction?.imageArray?.[0]?.secureUrl && (
                <div style={{ paddingLeft: "8px" }}>
                  <img
                    src={formData?.transaction?.imageArray?.[0]?.secureUrl}
                    width={200}
                  />
                </div>
              )}
              <CardBody>
                <Row>
                  <table class="table">
                    <tbody>
                      {inputFields &&
                        inputFields.length > 0 &&
                        inputFields.map(el => {
                          return (
                            <tr>
                              <td>{el.title}</td>
                              <td className="bold">
                                {typeof formData?.transaction?.[el.key] !=
                                "object"
                                  ? typeof formData?.transaction?.[el.key] ==
                                    "boolean"
                                    ? formData?.transaction?.[el.key]
                                      ? "YES"
                                      : "NO"
                                    : formData?.transaction?.[el.key] &&
                                      `${formData?.transaction?.[el.key]} ${
                                        el.suffix ? `(${el.suffix})` : ""
                                      }`
                                  : ""}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </Row>
              </CardBody>
            </Card>

            <Card></Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

View.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(View)
