import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Label,
    FormGroup
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { httpClient } from 'helpers/api_helper';

const Edit = props => {
    const [fieldKeys,setFieldKeys] = useState({});
    const [rerender,setRerender] = useState(false);

    const handleSubmit = async (event,values)=>{
        try {
            await httpClient.put(`/api/fieldKeys/${props.match.params.id}`,values)
            alert("field Keys updated successfully")
            props.history.push('/field-keys')
        } catch (error) {
            console.log("error",error)
        }
    }

    const getFieldsKey = async () => {
        try {
            const res = await httpClient.get(`/api/fieldKeys/${props.match.params.id}`)
            if(res?.data?.result && res?.data?.result?.length > 0){
                setFieldKeys(res.data.result[0]);
                setRerender(true)
            }
        } catch (error) {
            console.log(error?.message)
            setRerender(true)
        }
    }

    useEffect(()=>{
        getFieldsKey()
    },[])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Field Keys</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={8}>
                                <h6 className="page-title">Field Keys</h6>
                                <Link style={{ float: "right" }} to="/field-keys" >Field keys List</Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                    {
                                            rerender &&
                                        <AvForm 
                                            className="needs-validation"
                                            onValidSubmit={handleSubmit}
                                            model={fieldKeys}
                                        >
                                            <Row>
                                                 <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="code">
                                                            Field Title
                                                        </Label>
                                                        <AvField
                                                            name="title"
                                                            placeholder="Enter Field Title"
                                                            type="text"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="title"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="title">
                                                            Field key
                                                        </Label>
                                                        <AvField
                                                            name="key"
                                                            placeholder="Enter Field Key"
                                                            type="text"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="key"
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="fieldType">
                                                            Field Type
                                                        </Label>
                                                        <AvField
                                                            name="fieldType"
                                                            type="select"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="fieldType"
                                                        >
                                                            <option value="">select field type</option>
                                                            <option value="donorForm">Donor Form</option>
                                                            <option value="orderStages">Order Stages</option>
                                                            <option value="donorStages">Donor Stages</option>
                                                             <option value="donorFormOffice">Donor Form Office</option>
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        
                                            <div className='text-center'>
                                                <Button color="primary" type="submit">
                                                    Submit form
                                                </Button>
                                            </div>
                                        </AvForm>
                                    }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

        </React.Fragment>
    )
}

Edit.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(Edit)
