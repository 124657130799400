import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"

import { Button as MUIButton } from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"

//i18n
import { withTranslation } from "react-i18next"
import { DataGrid } from "@mui/x-data-grid"
import { httpClient } from "helpers/api_helper"
import { getIndexTitleDescription, TABLE_LIMIT } from "helpers/common"
import { AvField, AvForm } from "availity-reactstrap-validation"

const List = props => {
  const testResultFileRef = useRef(null)
  const [donors, setDonors] = useState([])
  const [donorsCount, setDonorsCount] = useState(0)
  const [stages, setStages] = useState([])
  const [updatePayload, setUpdatePayload] = useState({})
  const [dateTime, setDateTime] = useState("")
  const [rowId, setRowId] = useState("")

  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [rejectModal, setRejectModal] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleScheduleMeeting = async () => {
    await updateStatus({
      ...updatePayload,
      input: dateTime,
    })
  }

  const handleRejectModalClose = () => {
    setRejectModal(false)
  }

  const handleRejectDonnor = async reason => {
    await updateStatus({
      ...updatePayload,
      input: reason,
    })
  }

  const updateStatus = async payload => {
    try {
      const id = payload.id
      delete payload.id
      const res = await httpClient.put(`/api/intrestedDonor/${id}`, payload)
      if (res?.data?.result && res?.data?.result?.length > 0) {
        getDonors()
        getDonorStages()
        setRowId("")
        setUpdatePayload({})
        setDateTime("")
        handleClose()
        handleRejectModalClose()
      }
    } catch (error) {
      console.log(error.message)
      alert(error?.response?.data?.message)
    }
  }

  const handleChange = async (event, data) => {
    if (event.target.value) {
      let index = event.target.selectedIndex
      let optionElement = event.target.childNodes[index]
      let option = optionElement.getAttribute("data-metadata")
      let currentSelected = JSON.parse(option)

      let payload = stages.find(el => el.index == currentSelected.index)
      payload.id = data._id
      payload.idOrg = data.idOrg
      if (payload.title === "Rejected") {
        setUpdatePayload(payload)
        setRejectModal(true)
      } else if (payload.input == "date") {
        setUpdatePayload(payload)
        handleClickOpen()
      } else {
        updateStatus(payload)
      }
    }
  }

  const columns = [
    {
      field: "idAuthor.name",
      headerName: "Name",
      flex: 1,
      valueGetter: params => `${params?.row?.idAuthor?.name}`,
    },
    {
      field: "idAuthor.address.city",
      headerName: "Location",
      flex: 1,
      valueGetter: params => `${params?.row?.idAuthor?.address?.city}`,
    },
    {
      field: "statusStr",
      headerName: "Status",
      flex: 1,
      renderCell: params => {
        let currrentIndexWithString = stages.find(
          el => el.index == params?.row?.status
        )
        let currrentIndex = currrentIndexWithString?.index
        currrentIndex = currrentIndex ? parseInt(currrentIndex) : null
        let nextIndex
        if (currrentIndex >= 0) {
          nextIndex = stages.find(el => el.index > currrentIndex)?.index
        }
        return (
          <div>
            <select
              className="form-control mb-3"
              value={params?.row?.status}
              onChange={event => {
                handleChange(event, params.row)
              }}
            >
              <option value="">-- select status --</option>
              {stages?.map((el, index) => {
                return (
                  <option
                    key={index}
                    value={el.index}
                    data-metadata={JSON.stringify(el)}
                    disabled={
                      el.title == params?.row?.statusStr ||
                      el.index < 0 ||
                      el.index == nextIndex
                        ? false
                        : true
                    }
                  >
                    {el.title}
                  </option>
                )
              })}
            </select>
          </div>
        )
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: params => {
        return (
          <div>
            {params.row?.transaction && (
              <Link to={`/donors/${params.row?.transaction}`}>
                <Button color="primary">Edit</Button>
              </Link>
            )}{" "}
            {params.row?._id && (
              <Link to={`/donors/${params.row?._id}/view`}>
                <Button color="success">View</Button>
              </Link>
            )}{" "}
            {params.row?.transaction && (
              <>
                <input
                  type={"file"}
                  className="d-none"
                  ref={testResultFileRef}
                  onChange={async e => {
                    const formData = new FormData()
                    formData.append("file", e.target.files[0])
                    formData.append("idOrg", params.row.idOrg)
                  }}
                />
                <Button
                  color="primary"
                  onClick={() => testResultFileRef.current.click()}
                >
                  Upload test result
                </Button>
              </>
            )}
            {params.row.imageArray?.[0]?.secureUrl ? (
              <>
                <a
                  href={params.row.imageArray?.[0]?.secureUrl}
                  style={{ marginLeft: "4px" }}
                  target="_"
                >
                  View test result
                </a>
              </>
            ) : null}
          </div>
        )
      },
    },
  ]

  const getDonorsCount = async () => {
    try {
      const res = await httpClient.get("/api/count/intrestedDonor")
      if (res?.data?.result) {
        setDonorsCount(res.data.result)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  const getDonors = async () => {
    try {
      const res = await httpClient.get(
        `/api/intrestedDonor?populate=idAuthor&limit=${TABLE_LIMIT}&skip=${
          TABLE_LIMIT * (page - 1)
        }`
      )
      if (res?.data?.result && res?.data?.result?.length > 0) {
        setDonors(res.data.result)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  const getDonorStages = async () => {
    try {
      const res = await httpClient.get("/api/fieldKeys?key=donorStages")
      if (res?.data?.result && res?.data?.result?.length > 0) {
        const stages = []

        res.data.result?.[0]?.options?.map(el => {
          stages.push(getIndexTitleDescription(el))
        })
        console.log("stages", stages)
        setStages(stages)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    getDonorsCount()
    getDonorStages()
  }, [])

  useEffect(() => {
    getDonors()
  }, [page])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Offers List</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      All Donors
                      <Link style={{ float: "right" }} to="/donors/add">
                        Create
                      </Link>
                    </CardTitle>

                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <DataGrid
                          rows={donors}
                          columns={columns}
                          rowCount={donorsCount}
                          onPaginationModelChange={model => {
                            setPage(model.page + 1)
                          }}
                          pagination
                          paginationMode="server"
                          initialState={{
                            pagination: {
                              paginationModel: {
                                page: page - 1,
                                pageSize: TABLE_LIMIT,
                              },
                            },
                          }}
                          // pageSizeOptions={[5, 10]}
                          getRowId={row => row._id}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Schedule Meeting</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <DateTimePicker
                label="Select date and time"
                onAccept={date => {
                  console.log("new date", date.$d.toLocaleString())
                  setDateTime(date.$d.toLocaleString())
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleScheduleMeeting}>Apply</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={rejectModal} onClose={handleRejectModalClose} fullWidth>
        <DialogTitle>Reject reason</DialogTitle>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, payload) => {
            console.log({ e })
            e?.preventDefault()
            handleRejectDonnor(payload?.reason || "")
          }}
          model={{ reason: "" }}
        >
          <DialogContent>
            <AvField
              name="reason"
              placeholder="Enter reason"
              type="textarea"
              className="form-control"
              validate={{ required: { value: true } }}
              id="reason"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRejectModalClose}>Cancel</Button>
            <Button type="submit">Apply</Button>
          </DialogActions>
        </AvForm>
      </Dialog>
    </React.Fragment>
  )
}

List.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(List)
