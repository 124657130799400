import PropTypes from 'prop-types'
import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Label,
    FormGroup
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"

const Edit = props => {
    console.log(props.match.params.id)

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Update Page</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={8}>
                                <h6 className="page-title">Update Page {props.match.params.id}</h6>
                                <Link style={{ float: "right" }} to="/pages" >Page List</Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <AvForm className="needs-validation">
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom01">
                                                            First name
                                                        </Label>
                                                        <AvField
                                                            name="firstname"
                                                            placeholder="First name"
                                                            type="text"
                                                            errorMessage="Enter First Name"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="validationCustom01"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">
                                                            Last name
                                                        </Label>
                                                        <AvField
                                                            name="lastname"
                                                            placeholder="Last name"
                                                            type="text"
                                                            errorMessage="Enter Last name"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="validationCustom02"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom03">City</Label>
                                                        <AvField
                                                            name="city"
                                                            placeholder="City"
                                                            type="text"
                                                            errorMessage=" Please provide a valid city."
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="validationCustom03"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom04">State</Label>
                                                        <AvField
                                                            name="state"
                                                            placeholder="State"
                                                            type="text"
                                                            errorMessage="Please provide a valid state."
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="validationCustom04"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom05">Zip</Label>
                                                        <AvField
                                                            name="zip"
                                                            placeholder="Zip Code"
                                                            type="text"
                                                            errorMessage=" Please provide a valid zip."
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="validationCustom05"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <div className='text-center'>
                                                <Button color="primary" type="submit">
                                                    Submit form
                                                </Button>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

        </React.Fragment>
    )
}

Edit.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(Edit)
