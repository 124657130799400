import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"
import { httpClient } from "helpers/api_helper"

const Edit = props => {
  const [inputFields, setInputFields] = useState([])
  const [formData, setformData] = useState({})

  const getinputFields = async () => {
    try {
      const res = await httpClient.get("/api/fieldOptions?limit=200")
      if (res?.data?.result && res?.data?.result?.length != 0) {
        setInputFields(res.data.result)
        getformData()
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const getformData = async () => {
    try {
      const res = await httpClient.get(
        `/api/donorProfile/${props.match.params.id}`
      )
      if (res?.data?.result && res?.data?.result?.length != 0) {
        setformData(res.data.result[0])
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    getinputFields()
  }, [])

  const handleSubmit = async (event, values) => {
    try {
      await httpClient.put(`/api/donorProfile/${props.match.params.id}`, values)
      alert("donor updated successfully")
      props.history.push("/donors")
    } catch (error) {
      console.log("error", error)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Update Donors</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Update Donors</h6>
                <Link style={{ float: "right" }} to="/donors">
                  Donors List
                </Link>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    {formData && Object.keys(formData).length > 0 ? (
                      <AvForm
                        className="needs-validation"
                        model={formData}
                        onValidSubmit={handleSubmit}
                      >
                        <Row>
                          {inputFields &&
                            inputFields.length != 0 &&
                            inputFields.map((item, index) => {
                              if (
                                item.key === "imageArray" &&
                                item.valueType === "file"
                              ) {
                                return (
                                  <Col md="12" className="mb-2">
                                    <div style={{ paddingLeft: "8px" }}>
                                      <img
                                        src={
                                          formData?.[item?.key]?.[0]?.secureUrl
                                        }
                                        width={200}
                                      />
                                    </div>
                                  </Col>
                                )
                              } else if (
                                item.hasOwnProperty("options") ||
                                item.isArray
                              ) {
                                return (
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor={item._id}>
                                        {item.title}{" "}
                                        {item.suffix ? `(${item.suffix})` : ""}
                                      </Label>
                                      <AvField
                                        name={item.key}
                                        placeholder={item.title}
                                        type="select"
                                        className="form-control"
                                        // validate={{ required: { value: item.require} } }
                                        id={item._id}
                                      >
                                        <option value="">
                                          -----Select-----
                                        </option>
                                        {item?.options?.map(el => {
                                          return (
                                            <option value={el}>{el}</option>
                                          )
                                        })}
                                      </AvField>
                                    </FormGroup>
                                  </Col>
                                )
                              } else if (item.valueType == "boolean") {
                                return (
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <AvRadioGroup
                                        inline
                                        name={item.key}
                                        label={item.title}
                                      >
                                        <AvRadio label="Yes" value={true} />
                                        <AvRadio label="NO" value={false} />
                                      </AvRadioGroup>
                                    </FormGroup>
                                  </Col>
                                )
                              } else if (
                                item.valueType == "number" ||
                                "string"
                              ) {
                                return (
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor={item._id}>
                                        {item.title}{" "}
                                        {item.suffix ? `(${item.suffix})` : ""}
                                      </Label>
                                      <AvField
                                        name={item.key}
                                        placeholder={item.title}
                                        type="text"
                                        className="form-control"
                                        // validate={{ required: { value: item.require} } }
                                        id={item._id}
                                      />
                                    </FormGroup>
                                  </Col>
                                )
                              }
                            })}
                        </Row>
                        <div className="text-center">
                          <Button color="primary" type="submit">
                            Submit form
                          </Button>
                        </div>
                      </AvForm>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

Edit.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Edit)
