import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  FormGroup,
  CardTitle
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

const List = props => {

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Pages</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      All Pages
                      <Link style={{ float: "right" }} to="/pages/add" >Create</Link>
                    </CardTitle>

                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <Thead>
                            <Tr>
                              <Th>Company</Th>
                              <Th data-priority="1">Last Trade</Th>
                              <Th data-priority="3">Trade Time</Th>
                              <Th data-priority="1">Change</Th>
                              <Th data-priority="3">Prev Close</Th>
                              <Th data-priority="3">Open</Th>
                              <Th data-priority="6">Bid</Th>
                              <Th data-priority="6">Ask</Th>
                              <Th data-priority="6">1y Target Est</Th>
                              <Th data-priority="6">Action</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr>
                              <Th>
                                GOOG <span className="co-name">Google Inc.</span>
                              </Th>
                              <Td>597.74</Td>
                              <Td>12:12PM</Td>
                              <Td>14.81 (2.54%)</Td>
                              <Td>582.93</Td>
                              <Td>597.95</Td>
                              <Td>597.73 x 100</Td>
                              <Td>597.91 x 300</Td>
                              <Td>731.10</Td>
                              <Td>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                  <Link to={'/pages/1'}>
                                    <Button color="primary">Edit</Button>
                                  </Link>
                                </div>
                              </Td>
                            </Tr>
                            <Tr>
                              <Th>
                                AAPL <span className="co-name">Apple Inc.</span>
                              </Th>
                              <Td>378.94</Td>
                              <Td>12:22PM</Td>
                              <Td>5.74 (1.54%)</Td>
                              <Td>373.20</Td>
                              <Td>381.02</Td>
                              <Td>378.92 x 300</Td>
                              <Td>378.99 x 100</Td>
                              <Td>505.94</Td>
                              <Td>
                                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Link to={'/pages/2'}>
                                      <Button color="primary">Edit</Button>
                                    </Link>
                                  </div>
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

    </React.Fragment>
  )
}

List.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(List)
