import PropTypes from "prop-types"
import React from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Label,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { httpClient } from "helpers/api_helper"

const Add = props => {
  const handleSubmit = async (event, values) => {
    try {
      await httpClient.post("api/ivflabs", values)
      alert("Lab added successfully")
      props.history.push("/labs")
    } catch (error) {
      console.log("error", error)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Shipping cost</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Create shipping cost</h6>
                <Link style={{ float: "right" }} to="/shipping-cost">
                  Shipping cost List
                </Link>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={handleSubmit}
                    >
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="title">Name</Label>
                            <AvField
                              name="name"
                              placeholder="Enter name"
                              type="text"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                // maxLength: 6,
                              }}
                              id="name"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="title">Address</Label>
                            <AvField
                              name="address"
                              placeholder="Enter address"
                              type="text"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                // maxLength: 6,
                              }}
                              id="address"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="title">City</Label>
                            <AvField
                              name="city"
                              placeholder="Enter city"
                              type="text"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                // maxLength: 6,
                              }}
                              id="city"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="title">state</Label>
                            <AvField
                              name="state"
                              placeholder="Enter state"
                              type="text"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                // maxLength: 6,
                              }}
                              id="state"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="title">Unique ID</Label>
                            <AvField
                              name="uniqueId"
                              placeholder="Enter Unique ID"
                              type="text"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                // maxLength: 6,
                              }}
                              id="uniqueId"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="title">Pin code</Label>
                            <AvField
                              name="pincode"
                              placeholder="Enter pin code"
                              type="text"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                // maxLength: 6,
                              }}
                              id="title"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="text-center">
                        <Button color="primary" type="submit">
                          Submit form
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

Add.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Add)
