import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Label,
    FormGroup
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { httpClient } from 'helpers/api_helper';

const Add = props => {

    const handleSubmit = async (event,values)=>{
        try {
            values['startDate'] = 1620000000000
            values['endDate'] = 1620000000000
            await httpClient.post('/api/offers',values)
            alert("Offer added successfully")
            props.history.push('/offers')
        } catch (error) {
            console.log("error",error)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Offers</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={8}>
                                <h6 className="page-title">Create Offer</h6>
                                <Link style={{ float: "right" }} to="/offers" >Offers List</Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <AvForm 
                                            className="needs-validation"
                                            onValidSubmit={handleSubmit}
                                        >
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="title">
                                                            Title
                                                        </Label>
                                                        <AvField
                                                            name="title"
                                                            placeholder="Enter title"
                                                            type="text"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="title"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="code">
                                                            Coupon Code
                                                        </Label>
                                                        <AvField
                                                            name="code"
                                                            placeholder="Enter Coupon Code"
                                                            type="text"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="code"
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="type">
                                                            Type
                                                        </Label>
                                                        <AvField
                                                            name="type"
                                                            placeholder="Enter type"
                                                            type="select"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="type"
                                                        >
                                                            <option value="">select type</option>
                                                            <option value="offer">Offer</option>
                                                            <option value="coupon">Coupon</option>
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>


                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="discountType">
                                                            Discount Type
                                                        </Label>
                                                        <AvField
                                                            name="discountType"
                                                            placeholder="Enter Coupon discountType"
                                                            type="select"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="discountType"
                                                        >
                                                            <option value="">select discount type</option>
                                                            <option value="flat">Flat</option>
                                                            <option value="percentage">Percentage</option>
                                                            <option value="buygetfree">Buy Get Free</option>
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>

                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="discount">
                                                            Discount
                                                        </Label>
                                                        <AvField
                                                            name="discount"
                                                            placeholder="Enter Coupon discount"
                                                            type="number"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="discount"
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="maxDiscount">
                                                            Max Discount
                                                        </Label>
                                                        <AvField
                                                            name="maxDiscount"
                                                            placeholder="Enter max discount"
                                                            type="number"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="maxDiscount"
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="minimumCartValue">
                                                            Minimum Cart Value
                                                        </Label>
                                                        <AvField
                                                            name="minimumCartValue"
                                                            placeholder="Enter minimum cart value"
                                                            type="number"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="minimumCartValue"
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="minimumCartQty">
                                                            Minimum Cart Quantity
                                                        </Label>
                                                        <AvField
                                                            name="minimumCartQty"
                                                            placeholder="Enter minimum cart quantity"
                                                            type="number"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="minimumCartQty"
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="perUserLimit">
                                                            Per User Limit
                                                        </Label>
                                                        <AvField
                                                            name="perUserLimit"
                                                            placeholder="Enter per user limit"
                                                            type="number"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="perUserLimit"
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="statusStr">
                                                            Status
                                                        </Label>
                                                        <AvField
                                                            name="statusStr"
                                                            placeholder="Enter statusStr"
                                                            type="select"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="statusStr"
                                                        >
                                                            <option value="">select Status</option>
                                                            <option value="draft">Draft</option>
                                                            <option value="active">Coupon</option>
                                                            <option value="archive">Archive</option>
                                                            <option value="ended">Ended</option>
                                                            <option value="used">Used</option>
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>

                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="description">
                                                            Description
                                                        </Label>
                                                        <AvField
                                                            name="description"
                                                            placeholder="Enter Coupon description"
                                                            type="textarea"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="description"
                                                        />
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                        
                                            <div className='text-center'>
                                                <Button color="primary" type="submit">
                                                    Submit form
                                                </Button>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

        </React.Fragment>
    )
}

Add.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(Add)
