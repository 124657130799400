import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Label,
    FormGroup
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { httpClient } from 'helpers/api_helper';

const Edit = props => {
    console.log(props.match.params.id)

    const [formData, setformData] = useState({});
    const [staff, setStaff] = useState({});
    const [users, setUsers] = useState([]);
    const [branches, setBranches] = useState([]);

    const handleSubmit = async (event, values) => {
        console.log("values", values);
        try {
            await httpClient.put(`/api/staff/${values.userId}`, {
                "role": "staff",
                "idOrg": values.idOrg
            })
            alert("Staff created successfully")
            props.history.push('/staffs')
        } catch (error) {
            console.log("error", error)
        }
    }

    const getBranches = async () => {
        try {
            const res = await httpClient.get('/api/branches?fields=name')

            if (res?.data?.result && res?.data?.result?.length > 0) {
                setBranches(res.data.result)
            }
        } catch (error) {
            console.log(error?.message)
        }
    }

    const getUsers = async () => {
        try {
            const res = await httpClient.get('/api/user?fields=name')
            setUsers(res.data.result)
        } catch (error) {
            console.log(error)
        }
    }

    const getstaff = async () => {
        try {
            const res = await httpClient.get(`/api/staff/${props.match.params.id}`)
            if (res?.data?.result && res?.data?.result?.length > 0) {
                setStaff(res.data.result[0])
                setformData({
                    userId: res?.data?.result?.[0]?.idAuthor,
                    idOrg: res?.data?.result?.[0]?.idOrg
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getstaff()
        getBranches()
        getUsers()
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Offers</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={8}>
                                <h6 className="page-title">Update staff {props.match.params.id}</h6>
                                <Link style={{ float: "right" }} to="/staffs" >Staff List</Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        {
                                            formData && Object.keys(formData)?.length > 0 &&
                                            <AvForm className="needs-validation"
                                                model={formData}
                                                onValidSubmit={handleSubmit}
                                            >
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="validationCustom01">
                                                                Select User
                                                            </Label>
                                                            <AvField
                                                                name="userId"
                                                                type="select"
                                                                validate={{ required: { value: true } }}
                                                                className="form-control"
                                                                id="validationCustom01"
                                                            >
                                                                <option value=''>Select User</option>
                                                                {
                                                                    users && users?.length > 0 &&
                                                                    users.map((el) => {
                                                                        return <option value={el._id}>{el.name}</option>
                                                                    })
                                                                }
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="validationCustom02">
                                                                Select Branch
                                                            </Label>
                                                            <AvField
                                                                name="idOrg"
                                                                type="select"
                                                                className="form-control"
                                                                validate={{ required: { value: true } }}
                                                                id="validationCustom02"
                                                            >
                                                                <option value=''>Select Branch</option>
                                                                {
                                                                    branches && branches?.length > 0 &&
                                                                    branches.map((el) => {
                                                                        return <option value={el._id}>{el.name}</option>
                                                                    })
                                                                }
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <div className='text-center'>
                                                    <Button color="primary" type="submit">
                                                        Submit form
                                                    </Button>
                                                </div>
                                            </AvForm>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

        </React.Fragment>
    )
}

Edit.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(Edit)
