import PropTypes from 'prop-types'
import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    CardTitle,
    Label,
    FormGroup
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { httpClient } from 'helpers/api_helper';

const Edit = props => {
    console.log(props.match.params.id)

    const [branch,setBranch] = useState({});

    const handleSubmit = async (event,values)=>{
        try {
            await httpClient.put(`/api/branches`,values)
            alert("Branch updated successfully")
            props.history.push('/branches')
        } catch (error) {
            console.log("error",error)
        }
    }

    const getBranche = async (event,values)=>{
        try {
            const res = await httpClient.get(`/api/branches/${props.match.params.id}`)
            if(res?.data?.result && res?.data?.result?.length > 0){
                setBranch(res.data.result[0])
            }
        } catch (error) {
            console.log("error",error)
        }
    }

    useState(()=>{
        getBranche()
    },[])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Update Branche</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={12}>
                                <h6 className="page-title">Create Branche</h6>
                                <Link style={{ float: "right" }} to="/branches" >Branches List</Link>
                            </Col>
                        </Row>
                        {
                            branch && Object.keys(branch).length > 0 &&
                            <AvForm className="needs-validation"
                            model={branch}
                            onValidSubmit={handleSubmit}
                        >
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardTitle>Branch Info</CardTitle>
                                        <CardBody>
                                            
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="name">
                                                                Branch Name
                                                            </Label>
                                                            <AvField
                                                                name="name"
                                                                placeholder="Enter Branch Name"
                                                                type="text"
                                                                className="form-control"
                                                                id="name"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="subtitle">
                                                                Sub Title
                                                            </Label>
                                                            <AvField
                                                                name="subtitle"
                                                                placeholder="Enter Sub Title"
                                                                type="text"
                                                                className="form-control"
                                                                id="subtitle"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="description">Description</Label>
                                                            <AvField
                                                                name="description"
                                                                placeholder="Enter Description"
                                                                type="textarea"
                                                                className="form-control"
                                                                id="description"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="houseNo">House No.</Label>
                                                            <AvField
                                                                name="houseNo"
                                                                placeholder="Enter house no."
                                                                type="text"
                                                                className="form-control"
                                                                id="houseNo"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="streetOne">Street Line</Label>
                                                            <AvField
                                                                name="streetOne"
                                                                placeholder="Enter street"
                                                                type="text"
                                                                className="form-control"
                                                                id="streetOne"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="streetTwo">Street Line two</Label>
                                                            <AvField
                                                                name="streetTwo"
                                                                placeholder="Enter street"
                                                                type="text"
                                                                className="form-control"
                                                                id="streetTwo"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="landmark">Land Mark</Label>
                                                            <AvField
                                                                name="landmark"
                                                                placeholder="Enter land mark"
                                                                type="text"
                                                                className="form-control"
                                                                id="landmark"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="city">City</Label>
                                                            <AvField
                                                                name="city"
                                                                placeholder="Enter City"
                                                                type="text"
                                                                className="form-control"
                                                                id="city"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="state">State</Label>
                                                            <AvField
                                                                name="state"
                                                                placeholder="Enter state"
                                                                type="text"
                                                                className="form-control"
                                                                id="state"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="pincode">Pincode</Label>
                                                            <AvField
                                                                name="pincode"
                                                                placeholder="Enter pincode"
                                                                type="text"
                                                                className="form-control"
                                                                id="pincode"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="addType">Type</Label>
                                                            <AvField
                                                                name="addType"
                                                                type="select"
                                                                className="form-control"
                                                                id="addType"
                                                            >
                                                                <option value=''>select</option>
                                                                <option value='home'>home</option>
                                                                <option value='office'>office</option>
                                                                <option value='other'>other</option>
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col lg={12}>
                                    <Card>
                                        <CardTitle>Contact Info</CardTitle>
                                        <CardBody>
                                            
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="email">
                                                                Email
                                                            </Label>
                                                            <AvField
                                                                name="contactInfo.email"
                                                                placeholder="Enter email"
                                                                type="text"
                                                                className="form-control"
                                                                id="email"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="mobile">
                                                                Mobile No.
                                                            </Label>
                                                            <AvField
                                                                name="contactInfo.mobile"
                                                                placeholder="Enter mobile number"
                                                                type="text"
                                                                className="form-control"
                                                                id="mobile"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="isWhatsaap">
                                                                Is Mobile number is your whatsaap number
                                                            </Label>
                                                            <AvField
                                                                name="contactInfo.isWhatsaap"
                                                                type="select"
                                                                className="form-control"
                                                                id="isWhatsaap"
                                                            >
                                                                <option value={false}>Select</option>
                                                                <option value={true}>Yes</option>
                                                                <option value={false}>No</option>
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="whatsaap">Whatsaap No.</Label>
                                                            <AvField
                                                                name="contactInfo.whatsaap"
                                                                placeholder="Enter whatsapp number"
                                                                type="text"
                                                                className="form-control"
                                                                id="whatsaap"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="phone">Landline Number</Label>
                                                            <AvField
                                                                name="contactInfo.phone"
                                                                placeholder="Enter landline no."
                                                                type="text"
                                                                className="form-control"
                                                                id="phone"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="website">Website</Label>
                                                            <AvField
                                                                name="contactInfo.website"
                                                                placeholder="Enter website"
                                                                type="text"
                                                                className="form-control"
                                                                id="website"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="instagram">Instagram Profile Link</Label>
                                                            <AvField
                                                                name="contactInfo.instagram"
                                                                placeholder="Enter instagram profile link"
                                                                type="text"
                                                                className="form-control"
                                                                id="instagram"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="facebook">Facebook Profile Link</Label>
                                                            <AvField
                                                                name="contactInfo.facebook"
                                                                placeholder="Enter facebook profile link"
                                                                type="text"
                                                                className="form-control"
                                                                id="facebook"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="twitter">Twitter Profile Link</Label>
                                                            <AvField
                                                                name="contactInfo.twitter"
                                                                placeholder="Enter twitter profile link"
                                                                type="text"
                                                                className="form-control"
                                                                id="twitter"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="linkedin">Linkedin Profile Link</Label>
                                                            <AvField
                                                                name="contactInfo.linkedin"
                                                                placeholder="Enter linkedin profile link"
                                                                type="text"
                                                                className="form-control"
                                                                id="linkedin"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="youtube">Youtube Profile Link</Label>
                                                            <AvField
                                                                name="contactInfo.youtube"
                                                                placeholder="Enter youtube profile link"
                                                                type="text"
                                                                className="form-control"
                                                                id="youtube"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>
                            <div className='text-center'>
                                <Button color="primary" type="submit">
                                    Submit form
                                </Button>
                            </div>
                        </AvForm>
                        }
                        
                        
                    </div>
                </Container>
            </div>

        </React.Fragment>
    )
}

Edit.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(Edit)
