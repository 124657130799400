import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { DataGrid } from "@mui/x-data-grid"
import { httpClient } from "helpers/api_helper"
import { TABLE_LIMIT } from "helpers/common"

const List = props => {
  const [shippingCosts, setShippingCosts] = useState([])
  const [shippingCostsCount, setShippingCostsCount] = useState(0)
  const [page, setPage] = useState(1)

  const deleteData = async id => {
    try {
      if (confirm("Are your sure? you want to delete it.")) {
        await httpClient.delete(`/api/pincost/${id}`)
        getShippingCosts()
        getShippingCostsCount()
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  const columns = [
    {
      field: "pincode",
      headerName: "Pincode",
      flex: 1,
    },
    {
      field: "idOrg",
      headerName: "Branch",
      flex: 1,
      renderCell: params => {
        return <span>{params.row?.idOrg?.name}</span>
      },
    },
    {
      field: "cost",
      headerName: "Cost",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: params => {
        return (
          <Button color="danger" onClick={() => deleteData(params.row._id)}>
            Delete
          </Button>
        )
      },
    },
  ]

  const getShippingCostsCount = async () => {
    try {
      const res = await httpClient.get("/api/count/pincost")
      setShippingCostsCount(res.data?.result || 0)
    } catch (error) {
      console.log(error?.message)
    }
  }

  const getShippingCosts = async () => {
    try {
      const res = await httpClient.get(
        `api/pincost?populate=idOrg&sort=-dateCreated&limit=${TABLE_LIMIT}&skip=${
          TABLE_LIMIT * (page - 1)
        }`
      )
      console.log({ res })
      if (res?.data?.result && res?.data?.result?.length > 0) {
        setShippingCosts(res.data.result)
      } else {
        setShippingCosts([])
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    getShippingCostsCount()
  }, [])

  useEffect(() => {
    getShippingCosts()
  }, [page])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Shipping Cost List</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      All Shipping Cost
                      <Link
                        style={{ float: "right" }}
                        to="/shipping-cost/create"
                      >
                        Create
                      </Link>
                    </CardTitle>

                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <DataGrid
                          rows={shippingCosts}
                          columns={columns}
                          rowCount={shippingCostsCount}
                          onPaginationModelChange={model => {
                            setPage(model.page + 1)
                          }}
                          pagination
                          paginationMode="server"
                          initialState={{
                            pagination: {
                              paginationModel: {
                                page: page - 1,
                                pageSize: TABLE_LIMIT,
                              },
                            },
                          }}
                          // pageSizeOptions={[5, 10]}
                          getRowId={row => row._id}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

List.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(List)
