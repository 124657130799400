import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Label,
    FormGroup,
    Button as TButton
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { httpClient } from 'helpers/api_helper';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Create = props => {

    const [value, setValue] = useState(0);
    const [stepValue, setStepValue] = useState({
        userId: '',
        donorId: '',
        ivfLabId: '',
    });
    
    const [paymentMode, setPaymentMode] = useState([]);
    const [users, setUsers] = useState([]);
    const [donors, setDonors] = useState([]);
    const [ivfLabs, setIvfLabs] = useState([]);
    const [branches,setBranches] = useState([]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getBranches = async () => {
        try {
          const res = await httpClient.get('/api/branches?limit=250')
    
          if(res?.data?.result && res?.data?.result?.length > 0){
            setBranches(res.data.result)
          }
        } catch (error) {
          console.log(error?.message)
        }
      }

    const getPaymentMode = async () => {
        try {
            const res = await httpClient.get('/api/getpaymentgateway')
            setPaymentMode(res.data.result)
        } catch (error) {
            console.log(error)
        }
    }

    const getUsers = async () => {
        try {
            const res = await httpClient.get('/api/user?limit=250')
            setUsers(res.data.result)
        } catch (error) {
            console.log(error)
        }
    }

    const getDonors = async () => {
        try {
            const res = await httpClient.get('/api/donorProfile?limit=200&isPublic=true&fields=isPublic,idOrg,nam')
            if (res?.data?.result && res?.data?.result?.length > 0) {
                setDonors(res.data.result)
            }
        } catch (error) {
            console.log(error?.message)
        }
    }

    const getIvfLabs = async () => {
        try {
            const res = await httpClient.get('/api/ivflabs?limit=250')
            if (res?.data?.result && res?.data?.result?.length > 0) {
                setIvfLabs(res.data.result)
            }
        } catch (error) {
            console.log(error?.message)
        }
    }

    const createUser = async (event, values) => {
        try {
            await httpClient.post('/api/user', values)
            await getUsers();
            handlUclose()
        } catch (error) {
            console.log("error", error)
        }
    }

    const createDonor = async (event, values) => {
        try {
            await httpClient.post('/api/donorProfile', values)
            await getDonors();
            handlDclose()
        } catch (error) {
            console.log("error", error)
        }
    }

    const createIvflabs = async (event, values) => {
        try {
            await httpClient.post('/api/ivflabs', values)
            await getIvfLabs();
            handlFclose()
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        getBranches();
        getPaymentMode();
        getUsers();
        getDonors();
        getIvfLabs();
    }, [])

    const [uOpen, setUopen] = React.useState(false);
    const handleUopen = () => setUopen(true);
    const handlUclose = () => setUopen(false);

    const UserModal = () => {
        return <>
            <Modal
                open={uOpen}
                onClose={handlUclose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Create New User
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Row>
                            <Col lg={12}>
                                <AvForm
                                    className="needs-validation"
                                    onValidSubmit={createUser}
                                >
                                    <Row>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="name">
                                                    Name
                                                </Label>
                                                <AvField
                                                    name="name"
                                                    placeholder="Enter name"
                                                    type="text"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="name"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="phone">
                                                    Mobile No.
                                                </Label>
                                                <AvField
                                                    name="phone"
                                                    placeholder="Enter mobile no."
                                                    type="text"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="phone"
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="address">
                                                    city
                                                </Label>
                                                <AvField
                                                    name="idOrg"
                                                    placeholder="Enter city"
                                                    type="select"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="address"
                                                >
                                                    <option value="">Select city</option>
                                                    {
                                                        branches && branches.length != 0  &&
                                                        branches.map((item,index)=>{
                                                            return <option key={index} value={item._id}>{item.name}</option>
                                                        })
                                                    }
                                                </AvField>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <div className='text-center'>
                                        <TButton color="primary" type="submit">
                                            Submit form
                                        </TButton>
                                    </div>
                                </AvForm>
                            </Col>
                        </Row>
                    </Typography>
                </Box>
            </Modal>
        </>
    }


    const [dOpen, setDopen] = React.useState(false);
    const handleDopen = () => setDopen(true);
    const handlDclose = () => setDopen(false);

    const DonorModal = () => {
        return <>
            <Modal
                open={dOpen}
                onClose={handlDclose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Create Donor
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Row>
                            <Col lg={12}>
                                <AvForm
                                    className="needs-validation"
                                    onValidSubmit={createDonor}
                                >
                                    <Row>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="name">
                                                    Name
                                                </Label>
                                                <AvField
                                                    name="name"
                                                    placeholder="Enter name"
                                                    type="text"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="name"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="phone">
                                                    Mobile No.
                                                </Label>
                                                <AvField
                                                    name="phone"
                                                    placeholder="Enter mobile no."
                                                    type="text"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="phone"
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="address">
                                                    city
                                                </Label>
                                                <AvField
                                                    name="address.city"
                                                    placeholder="Enter city"
                                                    type="text"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="address"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <div className='text-center'>
                                        <TButton color="primary" type="submit">
                                            Submit form
                                        </TButton>
                                    </div>
                                </AvForm>
                            </Col>
                        </Row>
                    </Typography>
                </Box>
            </Modal>
        </>
    }

    const [fOpen, setFopen] = React.useState(false);
    const handleFopen = () => setFopen(true);
    const handlFclose = () => setFopen(false);

    const IVFModal = () => {
        return <>
            <Modal
                open={fOpen}
                onClose={handlFclose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Create New IVF center
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Row>
                            <Col lg={12}>
                                <AvForm
                                    className="needs-validation"
                                    onValidSubmit={createIvflabs}
                                >
                                    <Row>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="name">
                                                    Lab Name
                                                </Label>
                                                <AvField
                                                    name="name"
                                                    placeholder="Enter name"
                                                    type="text"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="name"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="phone">
                                                    Mobile No.
                                                </Label>
                                                <AvField
                                                    name="phone"
                                                    placeholder="Enter mobile no."
                                                    type="text"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="phone"
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="address">
                                                    city
                                                </Label>
                                                <AvField
                                                    name="city"
                                                    placeholder="Enter city"
                                                    type="text"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="address"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="pincode">
                                                    pincode
                                                </Label>
                                                <AvField
                                                    name="pincode"
                                                    placeholder="Enter pincode"
                                                    type="text"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="pincode"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <div className='text-center'>
                                        <TButton color="primary" type="submit">
                                            Submit form
                                        </TButton>
                                    </div>
                                </AvForm>
                            </Col>
                        </Row>
                    </Typography>
                </Box>
            </Modal>
        </>
    }

    const createOrder = async (event, values) => {
        try {

            if(!stepValue.userId){
                alert("please select user")
                return
            }

            if(!stepValue.donorId){
                alert("please select donor")
                return
            }

            if(!stepValue.ivfLabId){
                alert("please select ivf lab")
                return
            }
            const donor = donors.find((el) => el._id == stepValue.donorId);
            const lab = ivfLabs.find((el) => el._id == stepValue.ivfLabId);

            values.idOrg = donor.idOrg
            values.idAuthor = stepValue.userId
            values.address = {
                name : lab?.name,
                pincode :  lab?.pincode,
                state :  lab?.state,
                city :  lab?.city,
                phone :  lab?.phone,
                streetOne :  lab?.streetOne
            }

            values.orderItems = [
                {
                    productId:donor._id,
                    idOrg: donor.idOrg,
                    quantity:1
                }
            ]

            await httpClient.post('/api/orders', values)
            props.history.push('/orders')
        } catch (error) {
            console.log("error", error)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Create Order</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={12}>
                                <h6 className="page-title">
                                    Create Order
                                    <Link style={{ float: "right" }} to="/orders" >Order List</Link>
                                </h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Box
                                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                                >
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                        <Tab label="Select/Create User" {...a11yProps(0)} />
                                        <Tab label="Select/Create Donor" {...a11yProps(1)} />
                                        <Tab label="Select/Create Ivf Labs" {...a11yProps(2)} />
                                        <Tab label="Create Order" {...a11yProps(3)} />
                                    </Tabs>
                                    <TabPanel value={value} index={0}>
                                        <FormControl style={{ minWidth: 250 }}>
                                            <InputLabel id="demo-simple-select-label">Select User</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={stepValue.userId}
                                                label="Select User"
                                                onChange={(e) => { setStepValue({ ...stepValue, userId: e.target.value }) }}
                                            >
                                                {
                                                    users && users.length != 0 && users.map((el, index) => {
                                                        return <MenuItem value={el._id} key={index}>{el.name}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        <Button
                                            style={{ marginLeft: '20px', marginTop: '10px' }}
                                            variant="contained"
                                            onClick={handleUopen}
                                        >
                                            Create New User
                                        </Button>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <FormControl style={{ minWidth: 250 }}>
                                            <InputLabel id="demo-simple-select-label">Select Donor</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={stepValue.donorId}
                                                label="Select Donor"
                                                onChange={(e) => { setStepValue({ ...stepValue, donorId: e.target.value }) }}
                                            >
                                                {
                                                    donors && donors.length != 0 && donors.map((el, index) => {
                                                        return <MenuItem value={el._id} key={index}>{el.nam}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        {/* <Button
                                            style={{ marginLeft: '20px', marginTop: '10px' }}
                                            variant="contained"
                                            onClick={handleDopen}
                                        >
                                            Create New Donor
                                        </Button> */}
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <FormControl style={{ minWidth: 250 }}>
                                            <InputLabel id="demo-simple-select-label">Select IVFLAB</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={stepValue.ivfLabId}
                                                label="Select IVFLAB"
                                                onChange={(e) => { setStepValue({ ...stepValue, ivfLabId: e.target.value }) }}
                                            >
                                                {
                                                    ivfLabs && ivfLabs.length != 0 && ivfLabs.map((el, index) => {
                                                        return <MenuItem value={el._id} key={index}>{el.name} / {el.city} </MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        <Button
                                            style={{ marginLeft: '20px', marginTop: '10px' }}
                                            variant="contained"
                                            onClick={handleFopen}
                                        >
                                            Create New IVF Center
                                        </Button>
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>

                                        <Row>
                                            <Col lg={12}>
                                                <AvForm
                                                    className="needs-validation"
                                                    onValidSubmit={createOrder}
                                                >
                                                    <Row>
                                                        <Col md="6">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="name">
                                                                    Name
                                                                </Label>
                                                                <AvField
                                                                    name="name"
                                                                    placeholder="Enter name"
                                                                    type="text"
                                                                    className="form-control"
                                                                    validate={{ required: { value: true } }}
                                                                    id="name"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="6">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="phone">
                                                                    Mobile No.
                                                                </Label>
                                                                <AvField
                                                                    name="phone"
                                                                    placeholder="Enter mobile no."
                                                                    type="text"
                                                                    className="form-control"
                                                                    validate={{ required: { value: true } }}
                                                                    id="phone"
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md="6">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="address">
                                                                    city
                                                                </Label>
                                                                <AvField
                                                                    name="address.city"
                                                                    placeholder="Enter city"
                                                                    type="text"
                                                                    className="form-control"
                                                                    validate={{ required: { value: true } }}
                                                                    id="address"
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md="6">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="paymentMode">
                                                                    PaymentMode
                                                                </Label>
                                                                <AvField
                                                                    name="paymentMode"
                                                                    placeholder="select payment mode"
                                                                    type="select"
                                                                    className="form-control"
                                                                    validate={{ required: { value: true } }}
                                                                    id="paymentMode"
                                                                >
                                                                    <option value=''>Select Payment Mode</option>
                                                                    {
                                                                        paymentMode && paymentMode.length != 0 && paymentMode.map((item,index)=>{
                                                                            return <option value={item} key={index}>{item}</option>
                                                                        })
                                                                    }
                                                                </AvField>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                    <div className='text-center'>
                                                        <TButton color="primary" type="submit">
                                                            Submit form
                                                        </TButton>
                                                    </div>
                                                </AvForm>
                                            </Col>
                                        </Row>

                                    </TabPanel>
                                </Box>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            {UserModal()}
            {DonorModal()}
            {IVFModal()}
        </React.Fragment>
    )
}

Create.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(Create)
